<template>
  <div>
    <PageHeader :key="globalLanguage" :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{ $t("Customer") }}
                </label>
                <MultiSelectInput
                  v-model="companyId"
                  :options="customers"
                  label="companyName"
                  trackBy="id"
                  moduleName="customers"
                  :multiple="false"
                  ><template #option="{ props }">
                    <div>
                      <p>
                        {{ props.option.companyNumber }} -
                        {{ props.option.companyName }}
                      </p>
                    </div>
                  </template>
                </MultiSelectInput>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <div class="custom-dropdone">
                <div class="dropzone-area">
                  <div class="icon">
                    <feather-icon icon="UploadCloudIcon" size="48" />
                  </div>
                  <div class="text">
                    <h3>
                      {{ $t("Select a file") }}
                    </h3>
                  </div>
                  <input
                    id="hidden-input"
                    type="file"
                    ref="fileInput"
                    @change="handleFileChange"
                    class="hidden"
                  />
                  <b-button
                    variant="primary"
                    class="primary-btn gap-2"
                    type="button"
                    @click="$refs.fileInput.click()"
                  >
                    <feather-icon icon="UploadIcon" size="18" />
                    {{ $t("Select File") }}
                  </b-button>
                </div>
              </div>
              <div class="upload-file-prev card mt-2 p-2" v-if="!!base64Image">
                <p>
                  {{ base64Image.name }}
                </p>
                <div class="d-flex items-center gap-3">
                  <div class="preview-btn" @click="imagePreviewModal1 = true">
                    {{ $t("Preview") }}
                  </div>
                  <div class="trash-btn" @click.stop="deleteFile()">
                    <feather-icon icon="TrashIcon" />
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end gap-2">
        <router-link
          to="/document-templates"
          class="d-flex align-items-center gap-1 btn btn-secondary"
        >
          <feather-icon icon="XIcon" size="12" />
          <span>{{ $t("Cancel") }}</span>
        </router-link>
        <b-button
          variant="primary"
          @click="store"
          class="d-flex align-items-center gap-1"
          v-if="$can(`${$route.meta.permission}.create`)"
          :class="{ 'cursor-not-allowed': isFormInvalid }"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Save and Proceed") }}</span>
        </b-button>
      </div>
    </validation-observer>
    <div class="img-prev-modal" v-if="imagePreviewModal1">
      <div class="modal-content">
        <img
          class="img-prev"
          :src="'data:image\/png;base64,' + base64Image.base64"
          alt=""
        />
        <div class="close-modal" @click="closeImgPrev">
          <feather-icon size="18" icon="XIcon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
export default {
  computed: {
    ...mapGetters(["errors", "isLoading"]),
    ...mapGetters("customers", ["customers"]),
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Document Templates"),
          to: "/document-templates",
        },
        {
          text: this.$t("Create"),
          active: true,
        },
      ];
    },
    isFormInvalid() {
      return !this.companyId;
    },
  },
  components: {
    MultiSelectInput,
    PageHeader,
    ValidationProvider,
    ValidationObserver,
  },
  remember: "form",
  data() {
    return {
      name: "",
      companyId: "",
      form: new FormData(),
      selectedFile: null,
      base64Image: null,
      imagePreviewModal1: false,
    };
  },
  mounted() {
    if (!this.companies?.data?.length)
      this.$store.dispatch("customers/list", {
        page: 1,
        perPage: 25,
      });
  },
  methods: {
    async handleFileChange(event) {
      const file = event?.target?.files?.[0];
      this.form.append("upload_files", file);
      if (file) {
        this.selectedFile = file;
        this.convertToBase64(file);
      }
    },
    deleteFile() {
      this.selectedFile = null;
      this.base64Image = null;
    },

    convertToBase64(file) {
      const reader = new FileReader();
      // reader.readAsDataURL(file);
      reader.onload = (e) => {
        const requiredData = e.target.result.split(",");
        this.base64Image = {
          name: file.name,
          size: file.size,
          base64: requiredData[1],
        };
      };
      reader.readAsDataURL(file);
    },
    closeImgPrev() {
      this.imagePreviewModal1 = false;
    },
    async store() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.form.append(
            "data",
            JSON.stringify({ company_id: this.companyId?.id })
          );
          this.$store.commit("isLoading", true);
          await this.$store.dispatch("documentService/create", this.form);
          this.$refs.fileInput.value = null;
          await this.$store.dispatch("documentService/list");
          this.$router.push("/document-templates");
        }
      });
    },
  },
};
</script>
